<template>
    <section>
        <div style="height:calc(100vh - 94px)">
            <tabs :tabs="rutas" class="border-bottom" />
            <div class="row mx-0">
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    middleware:['auth'],
    data(){
        return {
            rutas: [
                {
                    titulo: 'Entregas A Leecheros',
                    ruta: 'admin.entregas.leecheros',
                },
                {
                    titulo: 'Mapa de Entregas a Leecheros',
                    ruta: 'admin.entregas.mapa-entregas-leecheros',
                    can: 'tab_entregas_mapa_leecheros'
                },
                {
                    titulo: 'Mapa de Entregas a Clientes',
                    ruta: 'admin.entregas.mapa-entregas-clientes',
                    can: 'tab_entregas_mapa_clientes'
                }
            ]
        }
    },
    mounted(){
        this.rutas[0].titulo = `Entregas a ${this.$config.vendedor}`
        this.rutas[1].titulo = `Mapa de entregas a ${this.$config.vendedor}`
        this.rutas[2].titulo = `Mapa de entregas a ${this.$config.cliente}`
    }
}
</script>
